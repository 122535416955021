import { assign, createMachine } from 'xstate';
import GetBoats from '../utils/GetBoats';
import GetVoyageDates from '../utils/GetVoyageDates';
import GetVoyages from '../utils/GetVoyages';

export const voyageDashState =
  /** @xstate-layout N4IgpgJg5mDOIC5QDcD2BPAhjAIp2AFgMoAumJYAxEQKIAyNAwgCoD6AQgPICCzA2gAYAuolAAHVLACWJKagB2okAA9EAFgBMAGhDpEAZgCcANgB0+42oDsAVgCMNjQA4rVgTasBfTzrRZc+MRkFKawYAA2YADGsvJQrABGqOSUgiJIIBLSsgpKqgg2xgKmAmrGToal+gJ2AlZqOnoIxhoapk7VTk7GNvpqhhoCxt6+GNhgeISk5GChEdGx8RAzlBAKs1LyaADWs37jk0Ezc5Exm0szCJtoUeRy8mlpSlky93mITvbmlS1qvRplLqNRB2eqmaz6OytKw9fRdOwjED7AJTYKzMKnRasZYUShgABO+NQ+NMYnC5AAZsSALamZETQLTEIYhbnbGXa6oW45B7CJ4ZF4894IT5tIYAwx2QxqJyaAT6YEiuymeyGNXqjWGJyI+mHJno+ZnOLs5mGrH01brUyc3Z0sYoo6mzFsnEG53G+lXLZcu4KR7CZ6SV65DL5DpOcz6GySqUDQpRxU9MzOQpQvpqMpDHX2hmo44so0XJ2sj05vGE4mk8kkKn42m6xlok4lotulusT2c7n3f3pcRBoWhj5GEp1Jx2IxQqxqxXxyMAioeSpWAHZ-y5x1twsmrfmsu0BgsVg4Xg0fn97JvIcikc2T6GfSQipqBx2RUZ4oDFp9UoVOxqKM1wORtjgAd0wV44kteQNm9W0GzzEJwMgqAvRuX1eSEc9MgHK9QHyDRH1MQxejsFwJw8ARZRsRNnHBQxbFhR8bA8BEfCRHM9SbZDFnLIkSTJSkaTtdcuLAiDFjQn0eV7QNLxDfDEElUxQQYuotWMBibAzWc6NcWo6ncCpnC8diEM3ZttySFI+DsPscPkxRr0KYpSnKSoAJqOoGl0RArH-UxnDUeVlwEaUoW8dj5FQCA4CUcz9Tk4MnMUhAAFpjEVNKbGIpwBHygrCpqbUzM4kDiys5ISCSwdUqMCMKnFF9Bjy-9ExsYpWhfPoGJaSobCAh19UsrFXRqvCVD8mF2mcf8qi8+pFQ0bTwQ0X4WOqVwWkGjdhoLUb8zNNl6XGhTJpFAEZvHYLPNqRbfJFOoVIzLVQVKKwnGcHaxKQiTzlOlLzusWizBXZqtQBSFykizwgA */
  createMachine(
    {
      id: 'voyageDashState',
      predictableActionArguments: true,

      context: {
        userEmail: '',
        voyageCollection: '',
        datesCollection: '',
        newValues: {},
        boatSelection: { label: 'select vessel...' },
        dateSelection: new Date(),
        boatList: [],
        voyageList: [],
        highlightedDates: [],
        activeVoyage: false,
        showDateSelect: false,
        showCards: false,
      },

      states: {
        selecting_boat: {
          always: [
            {
              target: 'selecting_date',
              cond: 'isVesselUser',
              actions: 'setBoatSelection',
            },
            {
              target: 'selecting_date',
              cond: 'alreadySelected',
            },
          ],
        },

        selecting_date: {
          entry: ['showDateSelect', 'resetHighlightedDates'],

          invoke: {
            src: 'getVoyageDates',

            onDone: {
              actions: 'setHighlightedDates',
            },

            onError: {
              target: 'selecting_date',
              actions: 'logError',
              internal: true,
            },
          },

          states: {
            selecting_voyage: {
              invoke: {
                src: 'getVoyages',

                onDone: {
                  actions: ['setVoyageList', 'setActiveVoyage', 'showCards'],
                },

                onError: {
                  target: 'selecting_voyage',
                  internal: true,
                  actions: 'logError',
                },
              },

              on: {
                SELECT_DATE: {
                  target: 'selecting_voyage',
                  actions: ['setDateSelection', 'resetVoyageList'],
                },
              },
            },
          },

          initial: 'selecting_voyage',
        },

        waiting: {
          invoke: {
            src: 'getBoats',

            onDone: {
              target: 'selecting_boat',
              actions: 'setBoatList',
            },

            onError: {
              target: 'waiting',
              internal: true,
              actions: 'logError',
            },
          },
        },
      },

      initial: 'waiting',

      on: {
        SELECT_BOAT: {
          target: '.selecting_date',
          actions: ['setBoatSelection', 'resetDateSelection'],
          internal: false,
        },
      },
    },
    {
      actions: {
        logError: (_, { data }) => console.log(data),
        setBoatList: assign({ boatList: (_, { data }) => data }),
        setVoyageList: assign({ voyageList: (_, { data }) => data }),
        resetVoyageList: assign({ voyageList: [] }),
        setHighlightedDates: assign({
          highlightedDates: (_, { data }) => data,
        }),
        resetHighlightedDates: assign({ highlightedDates: [] }),
        setBoatSelection: assign({
          boatSelection: ({ boatList }, { selection }) =>
            selection || boatList[0],
        }),
        setActiveVoyage: assign({
          activeVoyage: ({ voyageList }) => {
            let result = false;
            voyageList.forEach((voyage) =>
              voyage.status === 'In Progress' ? (result = true) : ''
            );
            return result;
          },
        }),
        showDateSelect: assign({ showDateSelect: true }),
        setDateSelection: assign({
          dateSelection: (_, { date }) => date,
        }),
        resetDateSelection: assign({ dateSelection: new Date() }),
        showCards: assign({ showCards: true }),
      },
      services: {
        getVoyageDates: ({ boatSelection, datesCollection }) =>
          GetVoyageDates(boatSelection.value, datesCollection),
        getVoyages: ({
          dateSelection,
          boatSelection,
          voyageCollection,
          newValues,
        }) =>
          GetVoyages(dateSelection, voyageCollection, boatSelection, newValues),
        getBoats: ({ userEmail }) => GetBoats(userEmail),
      },
      guards: {
        isVesselUser: ({ boatList }) => boatList.length === 1,
        alreadySelected: ({ boatSelection }) => boatSelection.value,
      },
    }
  );
