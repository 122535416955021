import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../../../../context/AuthContext';

import Card from '../../../../components/ui/Card';
import classes from './VPCard.module.css';

const VPCard = ({ formData, status }) => {
  const { user } = UserAuth();
  const navigate = useNavigate();

  const formLinkHandler = () => {
    if (user !== null) {
      if (status === 'New') formData.dateTime = new Date();
      navigate('/voyage-plan', {
        state: { formData: formData },
      });
    }
  };

  return (
    <Card>
      <div onClick={formLinkHandler} className={classes.VPCard}>
        <div className={classes.VPCard_bgImg} />
        <div className={classes.VPCard_header}>
          <h2>{`${formData.vesselName} Voyage Plan`}</h2>
          <p>{status}</p>
        </div>
        <div className={classes.VPCard_contentBx}>
          <p>{`Date: ${formData.dateTime.toDateString()}`}</p>
          <p>{`Point of Origin: ${formData.pointOfOrigin || 'not set'}`}</p>
          <p>{`Destination/Fleet: ${
            formData.destinationFleet || 'not set'
          }`}</p>
          <p>{`# of RAs: ${Object.keys(formData.riskAssessments).length}`}</p>
        </div>
      </div>
    </Card>
  );
};

export default VPCard;
