import InputField from '../../../../components/form/inputField';

const PreDepartureChecksComplete = ({ value, onChange, readOnly = false }) => {
  const initialForm = (event) => {
    const name = event.target.name;
    const input = event.target.value;
    value[name] = input;
    value['dateTime'] = new Date();
    event.target.style.backgroundColor = 'white';
    onChange({ target: { name: 'preDepartureChecksComplete', value: value } });
  };

  return (
    <>
      <h2>Pre-Departure checks complete: </h2>
      <InputField
        type="datetime-local"
        label="Date (auto-fills when initialed): "
        name="dateTime"
        value={value.dateTime || ''}
        required
        readOnly
      />
      <InputField
        type="text"
        label="Wheelman Initials: "
        name="wheelmanInitials"
        onChange={initialForm}
        value={value.wheelmanInitials || ''}
        required
        readOnly={readOnly}
      />
    </>
  );
};

export default PreDepartureChecksComplete;
