import Select from 'react-select';
import classes from './BoatSelect.module.css';

const BoatSelect = ({ boats, selection, selectHandler }) => {
  return (
    <Select
      id="boat"
      options={boats}
      onChange={selectHandler}
      className={classes.dash_dropdowns_boats}
      value={selection}
      isDisabled={boats.length === 1}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: 'rgba(0, 0, 0, 0.9)',
          primary: 'rgba(0, 0, 0, 0.8)',
        },
      })}
    />
  );
};

export default BoatSelect;
