import VPCard from '../components/ui/VPCard';
import NavBar from '../../../layouts/NavBar';
import classes from './VPDashboard.module.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import BoatSelect from '../../../components/ui/BoatSelect';
import { voyageDashState } from '../../../context/DashState';
import { useMachine } from '@xstate/react';
import { useLocation } from 'react-router-dom';

const VPDashboard = () => {
  const location = useLocation();
  const [state, sendState] = useMachine(voyageDashState, {
    context: {
      userEmail: location.state.userEmail,
      voyageCollection: 'Voyage Plans',
      datesCollection: 'App Component Functions/DatePicker/Voyage Plan Dates',
      newValues: {
        numOfLoads: 0,
        numOfEmpties: 0,
        vts: { vts0: { name: '', frequency: 0 } },
        riskAssessments: {},
      },
      boatSelection: location.state.boatSelection || {
        label: 'select vessel...',
      },
      dateSelection: location.state.dateSelection || new Date(),
    },
  });

  return (
    <>
      <NavBar returnPath="/Dashboard" />
      <main className={classes.dash_main}>
        <div className={classes.dash_dropdowns}>
          <BoatSelect
            boats={state.context.boatList}
            selection={state.context.boatSelection}
            selectHandler={(selection) => {
              sendState('SELECT_BOAT', {
                selection: selection,
              });
            }}
          />
          {state.context.showDateSelect && (
            <DatePicker
              selected={state.context.dateSelection}
              onChange={(date) => {
                sendState('SELECT_DATE', {
                  date: date,
                });
              }}
              className={classes.dash_dropdowns_voyages}
              highlightDates={state.context.highlightedDates}
            />
          )}
        </div>
        {state.context.showCards ? (
          <>
            {state.context.activeVoyage && (
              <p>
                Must complete Voyage Plan in progress before creating a new
                Voyage Plan
              </p>
            )}
            {state.context.voyageList.length > 0 ? (
              state.context.voyageList.map((voyage, index) => (
                <VPCard
                  formData={voyage.value}
                  key={index}
                  status={voyage.status}
                />
              ))
            ) : (
              <p>
                No Voyage Plan available for this date. (select today's date to
                create a new voyage plan or see active voyage plans)
              </p>
            )}
          </>
        ) : (
          <p>
            Select your vessel to see voyage plans in progress or create a new
            one.
          </p>
        )}
      </main>
    </>
  );
};

export default VPDashboard;
