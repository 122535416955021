export const FormatDate = (date, includeTime = false) => {
  let dateString = `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  if (includeTime)
    dateString += `T${date.getHours().toString().padStart(2, '0')}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  return dateString;
};

export const convertDates = (form) => {
  const data = form.data();
  for (const field in data) {
    if (field === 'dateTime') data[field] = data[field].toDate();
    if (typeof data[field] === 'object') {
      for (const subField in data[field])
        if (subField === 'dateTime')
          data[field][subField] = data[field][subField].toDate();
    }
  }
  return data;
};
