import { getSpecificDoc } from '../services/Firebase';

const GetVoyageDates = async (boatID, collectionName) => {
  const vesselDoc = await getSpecificDoc(collectionName, String(boatID));
  if (vesselDoc.exists()) {
    let dates = [];
    vesselDoc.data().dates.forEach((date) => {
      try{
        dates.push(date.toDate());
      }
      catch(err){
        console.error(err);
      }
    });
    console.log(`Voyage Plan Dates for vessel ID ${boatID}:`, dates);
    return dates;
  } else {
    console.error(
      `No dates are saved for vessel with ID ${boatID}. There may not be any Voyage Plans created for this vessel`
    );
    return;
  }
};

export default GetVoyageDates;
