import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';

import Card from './Card';
import classes from './AppCard.module.css';

const AppCard = ({ title, path, description, image }) => {
  const { user } = UserAuth();
  const navigate = useNavigate();

  const formLinkHandler = () => {
    if (user !== null && user.email !== undefined) {
      navigate(path, {
        state: { userEmail: user.email },
      });
    }
  };

  return (
    <Card>
      <div onClick={formLinkHandler} className={classes.AppCard}>
        <div
          className={classes.AppCard_bgImg}
          style={{ backgroundImage: `url(${image})` }}
        />
        <div className={classes.AppCard_header}>
          <h2>{title}</h2>
        </div>
        <div className={classes.AppCard_contentBx}>
          <p>{description}</p>
        </div>
      </div>
    </Card>
  );
};

export default AppCard;
