import { Routes, Route } from 'react-router-dom';
import { AuthContextProvider } from './context/AuthContext';
import PrivateRoutes from './utils/PrivateRoutes';
import Login from './pages/Login';
import DashboardPage from './pages/DashboardPage';
import VPDashboard from './apps/VoyagePlan/pages/VPDashboard';
import VoyagePlan from './apps/VoyagePlan/pages/VoyagePlan';

const App = () => {
  return (
    <>
      <AuthContextProvider>
        <Routes>
          <Route path={'/'} element={<Login />} exact />
          <Route path={'/Login'} element={<Login />} />
          <Route
            path={'/Dashboard'}
            element={
              <PrivateRoutes>
                <DashboardPage />
              </PrivateRoutes>
            }
          />
          <Route
            path={'/VP-Dashboard'}
            element={
              <PrivateRoutes>
                <VPDashboard />
              </PrivateRoutes>
            }
          />
          <Route
            path={'/voyage-plan'}
            element={
              <PrivateRoutes>
                <VoyagePlan />
              </PrivateRoutes>
            }
          />
        </Routes>
      </AuthContextProvider>
    </>
  );
};

export default App;
