import InputField from '../../../../components/form/inputField';

const VoyageCompletion = ({ value, onChange, readOnly = false }) => {
  const signForm = (event) => {
    const name = event.target.name;
    const input = event.target.value;
    value[name] = input;
    value['dateTime'] = new Date();
    event.target.style.backgroundColor = 'white';
    onChange({ target: { name: 'voyageCompletion', value: value } });
  };

  return (
    <>
      <h2>Voyage Completion: </h2>
      <InputField
        type="datetime-local"
        label="Date (auto-fills when signed): "
        name="dateTime"
        value={value.dateTime || ''}
        required
        readOnly
      />
      <InputField
        type="text"
        label="Signature: "
        name="signature"
        onChange={signForm}
        value={value.signature || ''}
        required
        readOnly={readOnly}
      />
    </>
  );
};

export default VoyageCompletion;
