import classes from './ConfirmPrompt.module.css';

const ConfirmPrompt = ({
  title,
  content,
  closePrompt,
  confirm,
  closeButton = 'No',
  confirmButton = 'Yes',
}) => {
  return (
    <div className={classes.backdrop}>
      <div className={classes.ConfirmPrompt}>
        <h2 className={classes.ConfirmPrompt_title}>{title}</h2>
        <p className={classes.ConfirmPrompt_content}>{content}</p>
        <div className={classes.ConfirmPrompt_actions}>
          <button onClick={confirm}>{confirmButton}</button>
          <button onClick={closePrompt}>{closeButton}</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPrompt;
