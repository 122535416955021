export const DataTypes = {
  isEditable: 'bool',
  vesselName: 'string',
  vesselID: 'string',
  pointOfOrigin: 'string',
  destinationFleet: 'string',
  estimatedDuration: 'int',
  dateTime: 'dateTime',
  airDraftWidthVerified: 'bool',
  vesUnderwayInspJA: 'bool',
  highWaterCond: 'bool',
  highWaterJA: 'bool',
  allWheelmanPosted: 'bool',
  adequateCrewing: 'bool',
  lightBoatFleet: 'bool',
  wheelmanExpCheck: 'bool',
  towConfigCheck: 'bool',
  specialInstructionOrderReview: 'bool',
  totalLength: 'int',
  breadth: 'int',
  height: 'int',
  depth: 'int',
  bargeInspFormsComp: 'bool',
  numOfLoads: 'int',
  numOfEmpties: 'int',
  bridgeLockDimVerified: 'bool',
  routesActiveRPExeptInFleet: 'bool',
  calcHPToTowRatio: 'bool',
  currentUpdatedAISInfo: 'bool',
  weatherAtDepart: 'string',
  windAtDepart: 'string',
  currentAtDepart: 'string',
  tidesAtDepart: 'string',
  riverStageAtDepart: 'string',
  otherCondAtDepart: 'string',
  weatherAtDest: 'string',
  windAtDest: 'string',
  currentAtDest: 'string',
  tidesAtDest: 'string',
  riverStageAtDest: 'string',
  otherCondAtDest: 'string',
  mastersNotes: 'string',
  vts: {
    name: 'string',
    frequency: 'int',
  },
  filledOutBy: 'string',
  routes: 'string',
  navigationHazards: 'string',
  oversizeTowPermitNum: {
    num0: 'int',
    num1: 'int',
    num2: 'int',
    num3: 'int',
  },
  preDepartureChecksComplete: {
    dateTime: 'dateTime',
    wheelmanInitials: 'string',
  },
  navRiskAssessmentScore: 'int',
  voyageCompletion: {
    dateTime: 'dateTime',
    signature: 'string',
  },
  towingAtOrAboveWheelhouse: {
    barge: 'string',
    bridge: 'string',
    verticalSpanClearance: 'float',
    riverStage: 'float',
    verticalClearanceAtRiverStage: 'float',
    airdraftOfItem: 'float',
    verticalClearance: 'float',
  },
  lockClearance: {
    name: 'string',
    length: 'float',
    width: 'float',
  },
  riskAssessments: {
    equipmentScore: 'int',
    voyageRouteParticularsScore: 'int',
    environmentScore: 'int',
    crewExperienceScore: 'int',
    assistVesselScore: 'int',
    planningScore: 'int',
    totalScore: 'int',
    riskMitigationNotes: 'string',
  },
};

export const invalidChars = /[*|":<>[\]{}`\\()';@&$/]/g;

export const validateInput = (value, field, subField = '') => {
  if (typeof value === 'object')
    Object.keys(value).forEach((key) => {
      value[key] = validateInput(value[key], field, key);
    });
  else {
    const dataType =
      subField === '' ? DataTypes[field] : DataTypes[field][subField];
    switch (dataType) {
      case 'dateTime':
        value = new Date(value);
        break;
      case 'int':
        value = Number(Number(value).toFixed(0));
        break;
      case 'bool':
        value = typeof value === 'string' ? value === 'Yes' : value;
        break;
      case 'float':
        value = Number(Number(value).toFixed(2));
        break;
      case 'string':
        value = value.replaceAll(invalidChars, '');
        break;
      default:
        console.error('Invalid data type');
    }
  }
  return value;
};
