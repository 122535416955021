const ScrollEffects = (classNames, defaultPos, newPos) => {
  let prevScrollpos = window.scrollY;
  window.onscroll = () => {
    let currentScrollPos = window.scrollY;
    for (const index in classNames) {
      if (prevScrollpos >= currentScrollPos) {
        document.getElementsByClassName(classNames[index])[0].style.top =
          defaultPos[index];
      } else {
        document.getElementsByClassName(classNames[index])[0].style.top =
          newPos[index];
      }
    }
    prevScrollpos = currentScrollPos;
  };
};

export default ScrollEffects;
