import React from 'react';
import classes from './Dropdown.module.css';

const Dropdown = ({ id, options = [], defaultSelected, onChange }) => {
  return (
    <fieldset className={classes.dropdown}>
      <input
        className={classes.dropdown_toggle}
        id="toggle"
        type="radio"
        name="option"
      />
      <label className={classes.dropdown_toggle_label} htmlFor="toggle">
        <b className={classes.dropdown_indicator} />
        <i className={classes.dropdown_menu}>Toggle</i>
      </label>
      <span className={classes.dropdown_options} id={id}>
        {options.map((label, index) => {
          return (
            <React.Fragment key={label}>
              <input
                type="radio"
                name="option"
                id={index + 1}
                value={index + 1}
                className={classes.dropdown_menu}
                onChange={onChange}
                defaultChecked={defaultSelected === index + 1}
              />
              <label htmlFor={index + 1}>{label}</label>
            </React.Fragment>
          );
        })}
      </span>
    </fieldset>
  );
};

export default Dropdown;
