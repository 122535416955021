import { useLocation } from 'react-router-dom';
import { UserAuth } from '../../../context/AuthContext';
import { VPStateProvider } from '../context/VPState';
import RAForm from './RAForm';
import VPForm from './VPForm';

const VoyagePlan = () => {
  const { user } = UserAuth();
  const location = useLocation();

  return (
    <VPStateProvider
      options={{
        context: {
          formData: location.state.formData,
          user: user,
          riskAssessmentCount: Object.keys(
            location.state.formData.riskAssessments
          ).length,
        },
      }}
    >
      <VPForm />
      <RAForm />
    </VPStateProvider>
  );
};

export default VoyagePlan;
