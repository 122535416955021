import { useEffect } from 'react';

const useTimer = (
  interval = 600000,
  disabled = false,
  onTrigger = () => {},
  ...triggerFunctionArgs
) => {
  useEffect(() => {
    const timerID = setInterval(() => {
      if (!disabled) onTrigger(...triggerFunctionArgs);
    }, interval);
    return () => {
      clearInterval(timerID);
    };
  }, [interval, onTrigger, disabled, triggerFunctionArgs]);
};

export default useTimer;
