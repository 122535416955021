import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import ScrollEffects from '../utils/ScrollEffects';
import classes from './NavBar.module.css';

const NavBar = ({ returnPath = '/Dashboard', children }) => {
  const { logOut, user } = UserAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };
    const handleOffline = () => {
      setIsOnline(false);
    };
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  ScrollEffects(
    [classes.nav, classes.nav_header, classes.nav_subheaders],
    ['0', '0', '80px'],
    ['-80px', '-80px', '0']
  );

  const dashBtnHandler = () => {
    if (user !== null && user.email !== undefined) {
      if (location.pathname === '/voyage-plan')
        navigate(returnPath, {
          state: {
            userEmail: user.email,
            boatSelection: {
              label: location.state.formData.vesselName,
              value: location.state.formData.vesselID,
            },
            dateSelection: location.state.formData.dateTime,
          },
        });
      else
        navigate(returnPath, {
          state: { userEmail: user.email },
        });
    }
  };

  const logOutHandler = async () => {
    try {
      await logOut();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.nav}>
      <header className={classes.nav_header}>
        <div className={classes.nav_logo} />
        <div className={classes.nav_user}>
          Welcome: {user && user.displayName}
        </div>
        <div className={isOnline ? classes.nav_online : classes.nav_offline}>
          {isOnline ? 'Online' : 'Offline'}
        </div>
        <div className={classes.nav_buttons}>
          {location.pathname !== '/Dashboard' && (
            <button
              className={classes.nav_button}
              onClick={dashBtnHandler}
              id="dash_btn"
            >
              {`Return To ${
                location.pathname === '/voyage-plan'
                  ? 'VP Dashboard'
                  : 'Dashboard'
              }`}
            </button>
          )}
          <button onClick={logOutHandler} className={classes.nav_button}>
            Log Out
          </button>
        </div>
      </header>
      <div className={classes.nav_subheaders}>{children}</div>
    </div>
  );
};

export default NavBar;
