import classes from './ClearanceTable.module.css';

const ClearanceTable = ({ values = {}, readOnly = false, onChange }) => {
  const rows = Object.keys(values).length;

  const update = (event) => {
    const [key, field] = event.target.name.split('_');
    const input = event.target.value;
    values[key][field] = input;
    values[key].verticalClearanceAtRiverStage =
      values[key].verticalSpanClearance - values[key].riverStage;
    values[key].verticalClearance =
      values[key].verticalClearanceAtRiverStage - values[key].airdraftOfItem;
    onChange({ target: { name: 'towingAtOrAboveWheelhouse', value: values } });
  };
  const addRowHandler = () => {
    values[`towingAtOrAboveWheelhouse${rows}`] = {
      barge: '',
      bridge: '',
      verticalSpanClearance: '',
      riverStage: '',
      verticalClearanceAtRiverStage: '',
      airdraftOfItem: '',
      verticalClearance: '',
    };
    onChange({ target: { name: 'towingAtOrAboveWheelhouse', value: values } });
  };
  const deleteRowHandler = () => {
    delete values[`towingAtOrAboveWheelhouse${rows - 1}`];
    onChange({ target: { name: 'towingAtOrAboveWheelhouse', value: values } });
  };
  const highlight = (event) => {
    if (document.getElementsByName(event.target.name).length > 0)
      document.getElementsByName(event.target.name)[0].select();
  };

  return (
    <>
      <table className={classes.clearance}>
        <tbody>
          <tr>
            <th>A</th>
            <th>B</th>
            <th>C</th>
            <th>D</th>
            <th>E</th>
            <th>F</th>
            <th>G</th>
          </tr>
          <tr className={classes.clearance_headers}>
            <th>BARGE</th>
            <th>BRIDGE</th>
            <th>VERTICAL SPAN CLEARANCE</th>
            <th>RIVERSTAGE</th>
            <th>VERTICAL CLEARANCE @ RIVER STAGE</th>
            <th>AIRDRAFT OF ITEM (Cargo/Equipment/Crane)</th>
            <th>VERTICAL CLEARANCE</th>
          </tr>
          {Object.keys(values).map((key) => (
            <tr key={key}>
              <td>
                <input
                  type="text"
                  name={`${key}_barge`}
                  onChange={update}
                  value={values[key]['barge']}
                  readOnly={readOnly}
                />
              </td>
              <td>
                <input
                  type="text"
                  name={`${key}_bridge`}
                  onChange={update}
                  value={values[key]['bridge']}
                  readOnly={readOnly}
                />
              </td>
              <td>
                <input
                  type="number"
                  name={`${key}_verticalSpanClearance`}
                  onChange={update}
                  onClick={highlight}
                  value={values[key]['verticalSpanClearance']}
                  readOnly={readOnly}
                />
              </td>
              <td>
                <input
                  type="number"
                  name={`${key}_riverStage`}
                  onChange={update}
                  onClick={highlight}
                  value={values[key]['riverStage']}
                  readOnly={readOnly}
                />
              </td>
              <td>
                <input
                  type="number"
                  name={`${key}_verticalClearanceAtRiverStage`}
                  value={values[key]['verticalClearanceAtRiverStage']}
                  readOnly
                />
              </td>
              <td>
                <input
                  type="number"
                  name={`${key}_airdraftOfItem`}
                  onChange={update}
                  onClick={highlight}
                  value={values[key]['airdraftOfItem']}
                  readOnly={readOnly}
                />
              </td>
              <td>
                <input
                  type="number"
                  name={`${key}_verticalClearance`}
                  value={values[key]['verticalClearance']}
                  readOnly
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {!readOnly && (
        <div className={classes.rowButtons}>
          <button
            className={classes.rowButton}
            type="button"
            onClick={addRowHandler}
          >
            Add Row
          </button>
          {rows > 0 && (
            <button
              className={classes.rowButton}
              type="button"
              onClick={deleteRowHandler}
            >
              Delete Row
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default ClearanceTable;
