import { useEffect } from 'react';
import { UserAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import classes from './Login.module.css';

const Login = () => {
  const { MsSignIn, user } = UserAuth();
  const navigate = useNavigate();

  const MsSignInHandler = async () => {
    try {
      await MsSignIn();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user !== null) {
      navigate('/Dashboard');
    }
  }, [user, navigate]);

  return (
    <div className={classes.root}>
      <div className={classes.MainContainer}>
        <h2 className={classes.Title}>MTC Voyage Plan</h2>

        <div className={classes.MissionImage} />

        <div className={classes.ButtonContainer}>
          <button className={classes.Button} onClick={MsSignInHandler}>
            Login
          </button>
        </div>

        <hr className={classes.Line} />

        <div>
          <div className={classes.ContactInfo}>
            <h4>Issues Signing In?</h4>
            <p>Contact IT via one of the following methods:</p>
            <ul>
              <li>Phone: 270.744.0053</li>
              <li>
                Email:{' '}
                <a href="mailto:help@marquettetrans.com">
                  help@marquettetrans.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
