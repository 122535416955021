import classes from './textArea.module.css';

const TextArea = ({
  name,
  label,
  value,
  rows,
  readOnly = false,
  required = false,
  onChange,
}) => {
  return (
    <div className={classes.area}>
      <label htmlFor={name}>{label}</label>
      <textarea
        id={name}
        name={name}
        rows={rows}
        onChange={(event) => {
          event.target.style.backgroundColor = 'white';
          onChange(event);
        }}
        value={value || ''}
        readOnly={readOnly}
        required={required}
      ></textarea>
    </div>
  );
};

export default TextArea;
