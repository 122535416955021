import InputField from '../../../../components/form/inputField';

const OversizeTowPermitNum = ({ value, onChange, readOnly = false }) => {
  const dataSync = (event) => {
    const name = event.target.name;
    const input = event.target.value;
    value[name] = input;
    onChange({ target: { name: 'oversizeTowPermitNum', value: value } });
  };

  return (
    <>
      <h3>Oversize Tow Permit #: </h3>
      <InputField
        type="number"
        label="1: "
        name="num0"
        onChange={dataSync}
        value={value.num0 || ''}
        readOnly={readOnly}
      />
      <InputField
        type="number"
        label="2: "
        name="num1"
        onChange={dataSync}
        value={value.num1 || ''}
        readOnly={readOnly}
      />
      <InputField
        type="number"
        label="3: "
        name="num2"
        onChange={dataSync}
        value={value.num2 || ''}
        readOnly={readOnly}
      />
      <InputField
        type="number"
        label="4: "
        name="num3"
        onChange={dataSync}
        value={value.num3 || ''}
        readOnly={readOnly}
      />
    </>
  );
};

export default OversizeTowPermitNum;
