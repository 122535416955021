import { useContext, createContext, useEffect, useState } from 'react';
import {
  OAuthProvider,
  signOut,
  onAuthStateChanged,
  signInWithRedirect,
  signInWithPopup,
} from 'firebase/auth';
import { auth, constraint, getDocCount } from '../services/Firebase';

const AuthContext = createContext({});

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});

  const MsSignIn = () => {
    const provider = new OAuthProvider('microsoft.com');

    provider.setCustomParameters({
      tenant: "7241719b-8269-429a-ba63-a3bc8dec8589" //process.env.REACT_APP_AZURE_TENANT_ID,
    });

    signInWithPopup(auth, provider)
      .then((result) => {
        //setUser(result.user);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const logOut = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ MsSignIn, logOut, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};

export const isAdmin = async (user) => {
  const admin =
    user.uid !== undefined
      ? await getDocCount(
          'Admins',
          constraint('__name__', '==', user.uid)
        ).then((count) => {
          return count === 1;
        })
      : false;
  return admin;
};
