import classes from './VTSTable.module.css';

const VTSTable = ({ values = {}, readOnly = false, onChange }) => {
  const rows = Object.keys(values).length;

  const update = (event) => {
    const [key, field] = event.target.name.split('_');
    const input = event.target.value;
    values[key][field] = input;
    onChange({ target: { name: 'vts', value: values } });
  };
  const addRowHandler = () => {
    values[`vts${rows}`] = { name: '', frequency: '' };
    onChange({ target: { name: 'vts', value: values } });
  };
  const deleteRowHandler = () => {
    delete values[`vts${rows - 1}`];
    onChange({ target: { name: 'vts', value: values } });
  };

  return (
    <>
      <table className={classes.VTSTable}>
        <tbody>
          <tr>
            <th>
              <h3>VTS</h3>
            </th>
          </tr>
          <tr>
            <th>Name</th>
            <th>Frequency</th>
          </tr>
          {Object.keys(values).map((key) => (
            <tr key={key}>
              <td>
                <input
                  type="text"
                  name={`${key}_name`}
                  onChange={update}
                  value={values[key]['name'] || ''}
                  readOnly={readOnly}
                />
              </td>
              <td>
                <input
                  type="number"
                  name={`${key}_frequency`}
                  onChange={update}
                  onClick={() => {
                    if (
                      document.getElementsByName(`${key}_frequency`).length > 0
                    )
                      document
                        .getElementsByName(`${key}_frequency`)[0]
                        .select();
                  }}
                  value={values[key]['frequency'] || 0}
                  readOnly={readOnly}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {!readOnly && (
        <div className={classes.rowButtons}>
          <button
            className={classes.rowButton}
            type="button"
            onClick={addRowHandler}
          >
            Add Row
          </button>
          {rows > 0 && (
            <button
              className={classes.rowButton}
              type="button"
              onClick={deleteRowHandler}
            >
              Delete Row
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default VTSTable;
