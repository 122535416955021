import classes from './WWCTable.module.css';

const WWCTable = ({ value, onChange, readOnly = false }) => {
  const row = (label, name, required = false) => {
    return (
      <tr>
        <th className={classes.WWCTable_label}>{label}</th>
        <td className={classes.WWCTable_data}>
          <input
            type="text"
            name={`${name}AtDepart`}
            onChange={(event) => {
              event.target.style.backgroundColor = 'white';
              onChange(event);
            }}
            value={value[`${name}AtDepart`] || ''}
            readOnly={readOnly}
            required={required}
          />
        </td>
        <td className={classes.WWCTable_data}>
          <input
            type="text"
            name={`${name}AtDest`}
            onChange={(event) => {
              event.target.style.backgroundColor = 'white';
              onChange(event);
            }}
            value={value[`${name}AtDest`] || ''}
            readOnly={readOnly}
            required={required}
          />
        </td>
      </tr>
    );
  };

  return (
    <>
      <table className={classes.WWCTable}>
        <tbody>
          <tr className={classes.WWCTable_headers}>
            <th className={classes.WWCTable_label}>
              Weather/Waterway Conditions:{' '}
            </th>
            <th className={classes.WWCTable_data}>At Time of Departure</th>
            <th className={classes.WWCTable_data}>Forecast - At Destination</th>
          </tr>
          {row('Weather: ', 'weather', true)}
          {row('Wind: ', 'wind')}
          {row('Current: ', 'current')}
          {row('Tides: ', 'tides')}
          {row('River Stage: ', 'riverStage', true)}
          {row('Other Conditions: ', 'otherCond')}
        </tbody>
      </table>
    </>
  );
};

export default WWCTable;
