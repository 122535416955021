import classes from './MessagePrompt.module.css';

const MessagePrompt = ({ title, content, button, closePrompt }) => {
  return (
    <div className={classes.backdrop}>
      <div className={classes.MessagePrompt}>
        <h2 className={classes.MessagePrompt_title}>{title}</h2>
        <p className={classes.MessagePrompt_content}>{content}</p>
        <div className={classes.MessagePrompt_actions}>
          <button onClick={closePrompt}>{button}</button>
        </div>
      </div>
    </div>
  );
};

export default MessagePrompt;
