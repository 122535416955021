import { constraint, getFilteredDocs } from '../services/Firebase';

const GetBoats = async (email) => {
  const boats = email.includes('@mtcves.com')
    ? await getFilteredDocs('Boats', constraint('email', '==', email))
    : await getFilteredDocs('Boats');
  const arr = [];
  boats.forEach((boat) =>
    arr.push({
      label: boat.id,
      value: boat.data().vesselId,
    })
  );
  return arr;
};

export default GetBoats;
