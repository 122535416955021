import NavBar from '../layouts/NavBar';
import AppCard from '../components/ui/AppCard';
import classes from './DashboardPage.module.css';

const DashboardPage = () => {
  return (
    <>
      <NavBar />
      <main className={classes.dash_main}>
        <AppCard
          title="Voyage Plan"
          path="/VP-Dashboard"
          description="Carefully plan your voyage, keep detailed information about the trip, and assess risk as you go. Access and print past Voyage Plans as needed."
          image={require('../assets/1.jpg')}
        />
      </main>
    </>
  );
};

export default DashboardPage;
