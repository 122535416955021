import TextArea from '../../../../components/form/textArea';
import classes from './RiskAssessment.module.css';

const RiskAssessment = ({ name, readOnly, values = {}, onChange }) => {
  const update = (event) => {
    const [key, field] = event.target.name.split('-');
    if (values[key] === undefined) values = { ...values, [key]: {} };
    if (field === 'riskMitigationNotes') {
      values[key][field] = event.target.value;
    } else {
      values[key][field] =
        event.target.value === ''
          ? ''
          : Number(event.target.value) > 3
          ? 3
          : Number(event.target.value) < 1
          ? 1
          : Number(event.target.value);
      values[key].totalScore =
        (values[key].equipmentScore || 0) +
        (values[key].voyageRouteParticularsScore || 0) +
        (values[key].environmentScore || 0) +
        (values[key].crewExperienceScore || 0) +
        (values[key].assistVesselScore || 0) +
        (values[key].planningScore || 0);
      onChange({
        target: {
          name: 'navRiskAssessmentScore',
          value: values[key].totalScore,
        },
      });
    }
    onChange({ target: { name: 'riskAssessments', value: values } });
  };

  return (
    <>
      <table className={classes.RAForm_table}>
        <thead className={classes.RAForm_table_headers}>
          <tr>
            <th colSpan="6" className={classes.RAForm_table_main_header}>
              Voyage Risk Assessment Explanation
            </th>
          </tr>
          <tr>
            <th className={classes.RAForm_table_header}>Factors</th>
            <th className={classes.RAForm_table_header}>Descriptions</th>
            <th className={classes.RAForm_table_header}>Score</th>
            <th className={classes.RAForm_table_header_low}>Low = 1</th>
            <th className={classes.RAForm_table_header_moderate}>
              Moderate = 2
            </th>
            <th className={classes.RAForm_table_header_high}>High = 3</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className={classes.RAForm_table_header}>Equipment</th>
            <td>
              <p>
                Vessel and Barge- Navigation Equipment, Vessel Systems all
                inspected and operating as designed, drafts, watertight doors
                secured.
              </p>
            </td>
            <td className={classes.RAForm_table_input}>
              <input
                type="number"
                max={3}
                min={1}
                name={`${name}-equipmentScore`}
                value={
                  values[name] === undefined
                    ? ''
                    : values[name].equipmentScore || ''
                }
                onChange={update}
                readOnly={readOnly}
              />
            </td>
            <td>
              <p>
                All Mechanical and structural equipment is operating in normal
                condition and without any issues.
              </p>
            </td>
            <td>
              <p>
                Crew is unfamiliar with Mechanical equipment. Some crew
                unfamiliar with certain equipment models. i.e. Furuno vs. JRC
                radars
              </p>
            </td>
            <td>
              <p>
                Mechanical equipment has failed or is in poor condition. Crew's
                familiarity with equipment is insufficient to operate safely
              </p>
            </td>
          </tr>
          <tr>
            <th className={classes.RAForm_table_header}>
              Voyage/Route Particulars
            </th>
            <td>
              <p>
                Route/Tow Information, Locks/VTS Zones, Waterway Information
                (Closures, Bridges, etc.) This information must be known and
                reviewed by all wheelman onboard
              </p>
            </td>
            <td className={classes.RAForm_table_input}>
              <input
                type="number"
                max={3}
                min={1}
                name={`${name}-voyageRouteParticularsScore`}
                value={
                  values[name] === undefined
                    ? ''
                    : values[name].voyageRouteParticularsScore || ''
                }
                onChange={update}
                readOnly={readOnly}
              />
            </td>
            <td>
              <p>
                Crew is well posted in area of operations and well informed of
                all navigational obstructions
              </p>
            </td>
            <td>
              <p>
                Crew is moderately familiar with waterway and infrastructure
                that will be encountered along the way.
              </p>
            </td>
            <td>
              <p>
                Crew is unfamiliar with voyage route and has no information
                about waterway.
              </p>
            </td>
          </tr>
          <tr>
            <th className={classes.RAForm_table_header}>Environment</th>
            <td>
              <p>
                Waterway Conditions (River Stages/Tide conditions/Wind speed for
                route, current velocity and set) & Special Instructions (USCG
                MSIB) (BNM); Vessel Traffic in the area, Inclement WX or
                restricted visibility
              </p>
            </td>
            <td className={classes.RAForm_table_input}>
              <input
                type="number"
                max={3}
                min={1}
                name={`${name}-environmentScore`}
                value={
                  values[name] === undefined
                    ? ''
                    : values[name].environmentScore || ''
                }
                onChange={update}
                readOnly={readOnly}
              />
            </td>
            <td>
              <p>
                Waterway conditions are negligible and safe. Normal conditions.
              </p>
            </td>
            <td>
              <p>
                Waterway conditions are higher than normal. Current and level
                are higher than normal. Wind speeds are likely to affect the
                safe maneuverability of the tow. Traffic is higher than normal.
              </p>
            </td>
            <td>
              <p>
                Waterway conditions are extreme. Very high river stage and swift
                current. Wind speeds/gusts are excessivly high, safe tow
                maneuverability is questionable. Heavy drift. Heavy Traffic.
                Visibility very low.
              </p>
            </td>
          </tr>
          <tr>
            <th className={classes.RAForm_table_header}>Crew Experience</th>
            <td>
              <p>
                Personnel Experience, Posting & 2nd Man in WH Instructions
                outlined for voyage or areas where most experienced person will
                be on watch.
              </p>
            </td>
            <td className={classes.RAForm_table_input}>
              <input
                type="number"
                max={3}
                min={1}
                name={`${name}-crewExperienceScore`}
                value={
                  values[name] === undefined
                    ? ''
                    : values[name].crewExperienceScore || ''
                }
                onChange={update}
                readOnly={readOnly}
              />
            </td>
            <td>
              <p>
                Crew is well posted in area and familiar with vessel. All crew
                have worked together for a while.
              </p>
            </td>
            <td>
              <p>
                Crew is somewhat familiar with waterway and vessel. Some crew
                are new to vessel and to working with each other.
              </p>
            </td>
            <td>
              <p>
                Crew is completely new to vessel and/or waterway. Not the normal
                crew they work with.
              </p>
            </td>
          </tr>
          <tr>
            <th className={classes.RAForm_table_header}>Assist Vessel</th>
            <td>
              <p>
                Fleeting IN/OUT, Locking, Bridge Transits, Topping, docking
                assistance.
              </p>
            </td>
            <td className={classes.RAForm_table_input}>
              <input
                type="number"
                max={3}
                min={1}
                name={`${name}-assistVesselScore`}
                value={
                  values[name] === undefined
                    ? ''
                    : values[name].assistVesselScore || ''
                }
                onChange={update}
                readOnly={readOnly}
              />
            </td>
            <td>
              <p>
                Appropriate amount of horsepower and assist vessels are present
                to assist in fleet, docks, etc. Ample room to maneuver.
              </p>
            </td>
            <td>
              <p>
                Limited amount of assist vessels and horsepower to help fleet or
                top around. Limited room for maneuverability.
              </p>
            </td>
            <td>
              <p>
                No assists available. Very little clearance for bridge transits
                and locks. Fleets are in close proximity to traffic, other
                fleets, bank.
              </p>
            </td>
          </tr>
          <tr>
            <th className={classes.RAForm_table_header}>Planning</th>
            <td>
              <p>
                Voyage Plan Complete, Pre-Voyage Checks –Plans and checks
                completed, and vessel walk around completed. All drops and
                pickups listed in Voyage Plan. Master’s orders clearly stated in
                Voyage Plan.
              </p>
            </td>
            <td className={classes.RAForm_table_input}>
              <input
                type="number"
                max={3}
                min={1}
                name={`${name}-planningScore`}
                value={
                  values[name] === undefined
                    ? ''
                    : values[name].planningScore || ''
                }
                onChange={update}
                readOnly={readOnly}
              />
            </td>
            <td>
              <p>
                Wheelman familiar with voyage planning and route. Nothing out of
                the ordinary during the pre- voyage checks
              </p>
            </td>
            <td>
              <p>
                Wheelman moderately familiar with Voyage plan and route. Minor
                issues noted during pre-voyage checks. Issues resolved before
                departure.
              </p>
            </td>
            <td>
              <p>
                Voyage plan is new to wheelman. Not enough information needed
                for voyage plan. Issues fond during pre-departure checks that
                cannot be resolved.
              </p>
            </td>
          </tr>
          <tr>
            <th className={classes.RAForm_table_header} colSpan="2">
              Total
            </th>
            <td
              className={
                values[name] !== undefined
                  ? values[name].totalScore !== undefined
                    ? values[name].totalScore < 10
                      ? classes.RAForm_table_header_low
                      : values[name].totalScore < 15
                      ? classes.RAForm_table_header_moderate
                      : classes.RAForm_table_header_high
                    : classes.RAForm_table_header_low
                  : classes.RAForm_table_header_low
              }
            >
              <input
                type="number"
                name={`${name}-totalScore`}
                value={
                  values[name] === undefined
                    ? ''
                    : values[name].totalScore || ''
                }
                readOnly
              />
            </td>
            <td colSpan="3">
              Some explanations overlap for a reason. We should not find any
              gaps when evaluating risk. Err on the side of caution. Use good
              Judgment. Be conservative with your scoring.
            </td>
          </tr>
        </tbody>
      </table>
      <table className={classes.RAForm_table_key}>
        <colgroup>
          <col className={classes.RAForm_table_key_low} />
          <col className={classes.RAForm_table_key_moderate} />
          <col className={classes.RAForm_table_key_high} />
        </colgroup>
        <thead>
          <tr>
            <th>Low Risk</th>
            <th>Moderate Risk</th>
            <th>High Risk</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>6-9</td>
            <td>10-14</td>
            <td>15-18</td>
          </tr>
        </tbody>
      </table>
      <TextArea
        label="Risk Mitigation Notes: "
        name={`${name}-riskMitigationNotes`}
        rows={5}
        onChange={update}
        value={
          values[name] === undefined
            ? ''
            : values[name].riskMitigationNotes || ''
        }
        readOnly={readOnly}
      />
    </>
  );
};

export default RiskAssessment;
