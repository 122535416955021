import { useContext } from 'react';
import NavBar from '../../../layouts/NavBar';
import MessagePrompt from '../../../components/ui/MessagePrompt';
import ConfirmPrompt from '../../../components/ui/ConfirmPrompt';
import RiskAssessment from '../components/form/RiskAssessment';
import classes from './RAForm.module.css';
import { useActor } from '@xstate/react';
import { VPStateContext } from '../context/VPState';

const RAForm = () => {
  const VPStateServices = useContext(VPStateContext);
  const [state] = useActor(VPStateServices.VPStateService);
  const { send } = VPStateServices.VPStateService;

  return (
    state.context.creatingRiskAssessment && (
      <>
        {state.context.showDeletePrompt && (
          <ConfirmPrompt
            title="Deleting Risk Assessment"
            content="Are you sure you want to delete this risk assessment? This cannot be undone."
            closePrompt={() => send('CONTINUE')}
            confirm={() => send('DELETE_RA')}
          />
        )}
        {state.context.showSavedPrompt && (
          <MessagePrompt
            title="Voyage Plan Saved!"
            content="The voyage plan has been saved."
            button="Continue"
            closePrompt={() => send('CONTINUE')}
          />
        )}
        <form className={classes.RAForm}>
          <input
            type="submit"
            disabled
            onClick={() => false}
            style={{ display: 'none' }}
          />
          <NavBar>
            <div className={classes.RAForm_header}>
              <h1 className={classes.RAForm_title}>{`Risk Assessment ${
                Number(state.context.activeRiskAssessment.slice(14)) + 1
              }`}</h1>
              <div className={classes.RAForm_buttons}>
                <button
                  className={classes.RAForm_button}
                  onClick={(event) => {
                    event.preventDefault();
                    send('TOGGLE_RA');
                  }}
                >
                  Return to Risk Assessments
                </button>
                <button
                  className={classes.RAForm_button}
                  onClick={(event) => {
                    event.preventDefault();
                    send('PROMPT_DELETE');
                  }}
                >
                  Delete
                </button>
                {state.context.formData.isEditable && (
                  <button
                    className={classes.RAForm_button}
                    onClick={(event) => {
                      event.preventDefault();
                      send('SAVE');
                    }}
                  >
                    Save
                  </button>
                )}
                <button
                  className={classes.RAForm_button}
                  onClick={(event) => {
                    event.preventDefault();
                    send('PRINT');
                  }}
                >
                  Print
                </button>
              </div>
            </div>
          </NavBar>
          <div className={classes.RAForm_main}>
            <div className={classes.RAForm_section}>
              <p className={classes.RAForm_instructions_important}>
                Complete this form prior to getting underway from any berth,
                fleet, being stopped in and along the waterway or entering a
                river from the Canal.
              </p>
              <p className={classes.RAForm_instructions}>
                All Risks will be assessed and outlined along with mitigation
                method developed
              </p>
              <p className={classes.RAForm_instructions}>
                Any voyage with unmitigated risk should not commence or should
                be terminated as soon as it is safe to do so. Contact Vessel
                Operations Immediately
              </p>
              <RiskAssessment
                name={state.context.activeRiskAssessment}
                values={state.context.formData.riskAssessments}
                onChange={(event) =>
                  send('SYNC_FORM_DATA', {
                    field: event.target.name,
                    value: event.target.value,
                  })
                }
                readOnly={!state.context.formData.isEditable}
              />
            </div>
          </div>
        </form>
      </>
    )
  );
};

export default RAForm;
