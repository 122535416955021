import { constraint, getFilteredDocs } from '../services/Firebase';
import { convertDates, FormatDate } from './FormatDate';

const GetVoyages = async (
  date,
  collectionName,
  boatSelection,
  newValues = {}
) => {
  const todaysDate = new Date();
  const queryText = `${boatSelection.value}_${FormatDate(date)}`;
  let dateIndex = 0;
  const voyages = await getFilteredDocs(
    collectionName,
    constraint('__name__', 'contains', queryText)
  );
  const voyageList = [];
  let activeVoyage = false;
  voyages.forEach((voyage) => {
    dateIndex++;
    const voyageDate = voyage.id.split('_')[1];
    const data = convertDates(voyage);
    voyageList.push({
      label: voyageDate,
      value: data,
      status: data.isEditable ? 'In Progress' : 'Complete',
    });
    if (data.isEditable) activeVoyage = true;
  });
  if (!activeVoyage && FormatDate(date) === FormatDate(todaysDate)) {
    const voyagesInProgress = await getFilteredDocs(
      collectionName,
      constraint('isEditable', '==', true),
      constraint('vesselID', '==', boatSelection.value)
    );
    voyagesInProgress.forEach((voyage) => {
      const voyageDate = voyage.id.split('_')[1];
      const data = convertDates(voyage);
      voyageList.push({
        label: voyageDate,
        value: data,
        status: 'In Progress',
      });
      activeVoyage = true;
    });
    if (!activeVoyage) {
      voyageList.push({
        label: FormatDate(todaysDate),
        value: {
          vesselName: boatSelection.label,
          vesselID: boatSelection.value,
          dateTime: todaysDate,
          isEditable: true,
          dateIndex: dateIndex,
          ...newValues,
        },
        status: 'New',
      });
    }
  }
  return voyageList;
};

export default GetVoyages;
