import { FormatDate } from '../../utils/FormatDate';
import classes from './inputField.module.css';

const InputField = ({
  name,
  label,
  type,
  value,
  readOnly = false,
  required = false,
  highlightOnClick = false,
  onChange,
}) => {
  value =
    type === 'datetime-local' && value !== '' ? FormatDate(value, true) : value;

  const highlight = (event) => {
    if (
      highlightOnClick &&
      document.getElementsByName(event.target.name).length > 0
    )
      document.getElementsByName(event.target.name)[0].select();
  };

  return (
    <div className={classes.input}>
      <label htmlFor={name}>{label}</label>
      <input
        type={type}
        id={name}
        name={name}
        onChange={(event) => {
          event.target.style.backgroundColor = 'white';
          onChange(event);
        }}
        onClick={highlight}
        value={value === undefined ? '' : value}
        readOnly={readOnly}
        required={required}
      />
    </div>
  );
};

export default InputField;
