import { initializeApp } from 'firebase/app';
import {
  collection,
  deleteDoc,
  doc,
  enableIndexedDbPersistence,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebase = {
  // apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  // authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  // projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  // appId: process.env.REACT_APP_FIREBASE_APP_ID,
  // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  apiKey: "AIzaSyBQzgJ9Yhzf8vNK6S5pA6fxWniz_K3EBUw",
  authDomain: "voyageplan-fb9cb.firebaseapp.com",
  projectId: "voyageplan-fb9cb",
  storageBucket: "voyageplan-fb9cb.appspot.com",
  messagingSenderId: "486957992646",
  appId: "1:486957992646:web:05d5aef9c98d845cf165c7",
  measurementId: "G-S1L1HXK2JR"
};

const app = initializeApp(firebase);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const saveForm = async (collectionName, docName, data) =>
  setDoc(doc(db, collectionName, docName), data);
export const deleteForm = async (collectionName, docName) =>
  deleteDoc(doc(db, collectionName, docName));
export const constraint = (field, comparison, value) => {
  return { field: field, comparison: comparison, value: value };
};
export const buildQuery = (collectionName, ...constraints) => {
  let queryConstraints = [];
  constraints.forEach((constraint) => {
    if (constraint.comparison === 'contains')
      queryConstraints.push(
        where(constraint.field, '>=', constraint.value),
        where(constraint.field, '<=', constraint.value + '\uf8ff')
      );
    else
      queryConstraints.push(
        where(constraint.field, constraint.comparison, constraint.value)
      );
  });
  return query(collection(db, collectionName), ...queryConstraints);
};
export const getFilteredDocs = async (collectionName, ...constraints) => {
  return await getDocs(buildQuery(collectionName, ...constraints));
};
export const getSpecificDoc = async (collectionName, docId) => {
  return await getDoc(doc(db, collectionName, docId));
};
export const getDocCount = async (collectionName, ...constraints) => {
  const snapshot = await getCountFromServer(
    buildQuery(collectionName, ...constraints)
  );
  return snapshot.data().count;
};
enableIndexedDbPersistence(db).catch((err) => {
  if (err.code === 'failed-precondition') {
    alert('Duplicate tab\nPlease close this tab.');
  } else if (err.code === 'unimplemented') {
    alert(
      'The current browser does not support all of the features required to enable persistence. Please use ' +
        'chrome or firefox.'
    );
  }
});
