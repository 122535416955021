import classes from './radioButton.module.css';

const RadioButton = ({
  name,
  label,
  value,
  required = false,
  readOnly = false,
  onChange,
}) => {
  const toggle = (event) => {
    event.target.parentNode.parentNode
      .querySelectorAll('label')
      .forEach((option) => {
        option.style.backgroundColor = '';
      });
    onChange(event);
  };

  return (
    <div className={classes.radio}>
      <label>{label}</label>
      <div className={classes.radio_options}>
        <span className={classes.radio_option}>
          <input
            type="radio"
            id={`${name}Yes`}
            name={name}
            value="Yes"
            onChange={toggle}
            checked={value || false}
            disabled={readOnly && !value}
            required={required}
          />
          <label htmlFor={`${name}Yes`} className={classes.radio_label}>
            Yes
          </label>
        </span>
        <span className={classes.radio_option}>
          <input
            type="radio"
            id={`${name}No`}
            name={name}
            value="No"
            onChange={toggle}
            checked={value === undefined ? false : !value}
            disabled={readOnly && (value === undefined ? true : value)}
            required={required}
          />
          <label htmlFor={`${name}No`} className={classes.radio_label}>
            No
          </label>
        </span>
      </div>
    </div>
  );
};

export default RadioButton;
