import Card from '../../../../components/ui/Card';
import classes from './RACard.module.css';
import { useContext } from 'react';
import { VPStateContext } from '../../context/VPState';
import { useSelector } from '@xstate/react';

const RACard = ({ name, status }) => {
  const VPStateServices = useContext(VPStateContext);
  const formData = useSelector(
    VPStateServices.VPStateService,
    (state) => state.context.formData
  );
  const { send } = VPStateServices.VPStateService;

  return (
    <Card>
      <div
        onClick={() => send('TOGGLE_RA', { name: name })}
        className={classes.RACard}
      >
        <div className={classes.RACard_bgImg} />
        <div className={classes.RACard_header}>
          <h2>{formData.vesselName}</h2>
          <h2>{`Risk Assessment ${Number(name.slice(14)) + 1}`}</h2>
          <p>{status}</p>
        </div>
        <div className={classes.RACard_contentBx}>
          <p>{`Date: ${formData.dateTime.toDateString()}`}</p>
          <p>{`Point of Origin: ${formData.pointOfOrigin || 'not set'}`}</p>
          <p>{`Destination/Fleet: ${
            formData.destinationFleet || 'not set'
          }`}</p>
        </div>
      </div>
    </Card>
  );
};

export default RACard;
