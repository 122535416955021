import useTimer from '../../../utils/useTimer';
import NavBar from '../../../layouts/NavBar';
import ConfirmPrompt from '../../../components/ui/ConfirmPrompt';
import MessagePrompt from '../../../components/ui/MessagePrompt';
import InputField from '../../../components/form/inputField';
import TextArea from '../../../components/form/textArea';
import RadioButton from '../../../components/form/radioButton';
import DurationPicker from '../../../components/form/DurationPicker';
import Dropdown from '../../../components/form/Dropdown';
import ClearanceTable from '../components/form/ClearanceTable';
import LockTable from '../components/form/LockTable';
import WWCTable from '../components/form/WWCTable';
import VTSTable from '../components/form/VTSTable';
import RiskAssessment from '../components/form/RiskAssessment';
import classes from './VPForm.module.css';
import { useActor } from '@xstate/react';
import { VPStateContext } from '../context/VPState';
import OversizeTowPermitNum from '../components/form/OversizeTowPermitNum';
import PreDepartureChecksComplete from '../components/form/PreDepartureChecksComplete';
import VoyageCompletion from '../components/form/VoyageCompletion';
import { useContext } from 'react';

const VPForm = () => {
  const VPStateServices = useContext(VPStateContext);
  const [state] = useActor(VPStateServices.VPStateService);
  const { send } = VPStateServices.VPStateService;
  useTimer(
    600000,
    !state.context.formData.isEditable || !state.context.changes,
    send,
    'SAVE',
    { noPrompt: true }
  );

  return (
    !state.context.creatingRiskAssessment && (
      <>
        {state.context.showDeletePrompt && (
          <ConfirmPrompt
            title="Deleting Voyage Plan"
            content="Are you sure you want to delete the voyage plan? This cannot be undone."
            closePrompt={() => send('CONTINUE')}
            confirm={() => send('DELETE')}
          />
        )}
        {state.context.showSubmitPrompt && (
          <ConfirmPrompt
            title="Submitting Voyage Plan"
            content="Are you sure you want to submit the voyage plan? This will lock the voyage plan and prevent further changes."
            closePrompt={() => send('CONTINUE')}
            confirm={() =>
              send('SUBMIT', { field: 'isEditable', value: false })
            }
          />
        )}
        {state.context.showSavedPrompt && (
          <MessagePrompt
            title="Voyage Plan Saved!"
            content="The voyage plan has been saved."
            button="Continue"
            closePrompt={() => send('CONTINUE')}
          />
        )}
        {state.context.showNoChangesPrompt && (
          <MessagePrompt
            title="No Changes"
            content="There are currently no changes to save."
            button="Continue"
            closePrompt={() => send('CONTINUE')}
          />
        )}
        {state.context.showOfflinePrompt && (
          <MessagePrompt
            title="Offline"
            content="You are currently offline. Do not close tab until connection is restored or you will lose your changes."
            button="Continue"
            closePrompt={() => send('CONTINUE')}
          />
        )}
        <form
          className={classes.VPForm}
          onSubmit={(event) => event.preventDefault()}
        >
          <input
            type="submit"
            disabled
            onClick={() => false}
            style={{ display: 'none' }}
          />
          <NavBar returnPath="/VP-Dashboard">
            <div className={classes.VPForm_header}>
              <h1 className={classes.VPForm_title}>Voyage Plan</h1>
              <h3
                className={
                  state.context.formData.isEditable
                    ? classes.VPForm_inprogress
                    : classes.VPForm_complete
                }
              >
                {state.context.formData.isEditable ? 'In Progress' : 'Complete'}
              </h3>
              <h3 className={classes.VPForm_title}>
                # of RAs:{' '}
                {Object.keys(state.context.formData.riskAssessments).length}
              </h3>
              <div className={classes.VPForm_buttons}>
                <Dropdown
                  onChange={(event) =>
                    send('CHANGE_PAGE', { page: Number(event.target.value) })
                  }
                  id="page_select"
                  options={['Page 1', 'Page 2', 'Page 3', 'RAs (4)']}
                  defaultSelected={state.context.page}
                  className={classes.VPForm_button}
                />
                {(state.context.formData.isEditable ||
                  state.context.isAdmin) && (
                  <button
                    className={classes.VPForm_button}
                    onClick={(event) => {
                      event.preventDefault();
                      send('PROMPT_DELETE');
                    }}
                  >
                    Delete
                  </button>
                )}
                {!state.context.formData.isEditable &&
                  state.context.isAdmin && (
                    <button
                      className={classes.VPForm_button}
                      onClick={(event) => {
                        event.preventDefault();
                        send('UNLOCK', { field: 'isEditable', value: true });
                      }}
                    >
                      Unlock Form
                    </button>
                  )}
                {state.context.formData.isEditable && (
                  <button
                    className={classes.VPForm_button}
                    onClick={(event) => {
                      event.preventDefault();
                      send('SAVE');
                    }}
                  >
                    Save
                  </button>
                )}
                <button
                  className={classes.VPForm_button}
                  onClick={(event) => {
                    event.preventDefault();
                    send('PRINT');
                  }}
                >
                  Print
                </button>
                {state.context.formData.isEditable && (
                  <button
                    className={classes.VPForm_button}
                    onClick={() => {
                      send('PROMPT_SUBMIT');
                    }}
                  >
                    Submit & Complete
                  </button>
                )}
              </div>
            </div>
          </NavBar>
          <div className={classes.VPForm_main}>
            {(state.context.showAllPages || state.context.page === 1) && (
              <div className={classes.VPForm_page_1} id="page_1">
                <h1 className={classes.VPForm_page_header}>
                  (Page 1 of 3) Filled Out By All Boats
                </h1>
                <div className={classes.VPForm_section}>
                  <InputField
                    type="text"
                    label="Vessel Name: "
                    name="vesselName"
                    value={state.context.formData.vesselName}
                    required
                    readOnly
                  />
                  <InputField
                    type="text"
                    label="Point of Origin: "
                    name="pointOfOrigin"
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    value={state.context.formData.pointOfOrigin}
                    required
                    readOnly={!state.context.formData.isEditable}
                  />
                  <InputField
                    type="text"
                    label="Destination/Fleet: "
                    name="destinationFleet"
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    value={state.context.formData.destinationFleet}
                    required
                    readOnly={!state.context.formData.isEditable}
                  />
                  <DurationPicker
                    label="Estimated Duration: "
                    name="estimatedDuration"
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    value={state.context.formData.estimatedDuration}
                    required
                    readOnly={!state.context.formData.isEditable}
                  />
                  <InputField
                    type="datetime-local"
                    label="Date/Time: "
                    name="dateTime"
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    value={state.context.formData.dateTime}
                    required
                    readOnly
                  />
                  <RadioButton
                    label="Air draft/width of Vessel and Tow verified for all bridges/locks by 2 feet: "
                    name="airDraftWidthVerified"
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    value={state.context.formData.airDraftWidthVerified}
                    required
                    readOnly={!state.context.formData.isEditable}
                  />
                  <RadioButton
                    label="Vessel Underway Inspection Job Aid review (164.80): "
                    name="vesUnderwayInspJA"
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    value={state.context.formData.vesUnderwayInspJA}
                    required
                    readOnly={!state.context.formData.isEditable}
                  />
                  <RadioButton
                    label="Are there high water conditions? "
                    name="highWaterCond"
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    value={state.context.formData.highWaterCond}
                    required
                    readOnly={!state.context.formData.isEditable}
                  />
                  <RadioButton
                    label="If yes, did you review the high water job aid? "
                    name="highWaterJA"
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    value={state.context.formData.highWaterJA}
                    required
                    readOnly={!state.context.formData.isEditable}
                  />
                </div>
                <div className={classes.VPForm_section}>
                  <h2>Crew</h2>
                  <RadioButton
                    label="All Wheelman posted: "
                    name="allWheelmanPosted"
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    value={state.context.formData.allWheelmanPosted}
                    required
                    readOnly={!state.context.formData.isEditable}
                  />
                  <RadioButton
                    label="Adequate Crewing: "
                    name="adequateCrewing"
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    value={state.context.formData.adequateCrewing}
                    required
                    readOnly={!state.context.formData.isEditable}
                  />
                </div>
                <div className={classes.VPForm_section}>
                  <h2>Tow</h2>
                  <RadioButton
                    label="Light Boat/Fleeting "
                    name="lightBoatFleet"
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    value={state.context.formData.lightBoatFleet}
                    required
                    readOnly={!state.context.formData.isEditable}
                  />
                  <RadioButton
                    label="All Wheelman experienced with tow configuration: "
                    name="wheelmanExpCheck"
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    value={state.context.formData.wheelmanExpCheck}
                    required
                    readOnly={!state.context.formData.isEditable}
                  />
                  <RadioButton
                    label="Configuration of tow in accordance with guidelines/policies for route: "
                    name="towConfigCheck"
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    value={state.context.formData.towConfigCheck}
                    required
                    readOnly={!state.context.formData.isEditable}
                  />
                  <RadioButton
                    label="Review orders, special instructions: "
                    name="specialInstructionOrderReview"
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    value={state.context.formData.specialInstructionOrderReview}
                    required
                    readOnly={!state.context.formData.isEditable}
                  />
                  {!state.context.formData.lightBoatFleet && (
                    <>
                      <InputField
                        type="number"
                        label="Total Length: "
                        name="totalLength"
                        onChange={(event) =>
                          send('SYNC_FORM_DATA', {
                            field: event.target.name,
                            value: event.target.value,
                          })
                        }
                        value={state.context.formData.totalLength}
                        required={!state.context.formData.lightBoatFleet}
                        readOnly={!state.context.formData.isEditable}
                      />
                      <InputField
                        type="number"
                        label="Breadth: "
                        name="breadth"
                        onChange={(event) =>
                          send('SYNC_FORM_DATA', {
                            field: event.target.name,
                            value: event.target.value,
                          })
                        }
                        value={state.context.formData.breadth}
                        required={!state.context.formData.lightBoatFleet}
                        readOnly={!state.context.formData.isEditable}
                      />
                      <InputField
                        type="number"
                        label="Height: "
                        name="height"
                        onChange={(event) =>
                          send('SYNC_FORM_DATA', {
                            field: event.target.name,
                            value: event.target.value,
                          })
                        }
                        value={state.context.formData.height}
                        required={!state.context.formData.lightBoatFleet}
                        readOnly={!state.context.formData.isEditable}
                      />
                      <InputField
                        type="number"
                        label="Depth: "
                        name="depth"
                        onChange={(event) =>
                          send('SYNC_FORM_DATA', {
                            field: event.target.name,
                            value: event.target.value,
                          })
                        }
                        value={state.context.formData.depth}
                        required={!state.context.formData.lightBoatFleet}
                        readOnly={!state.context.formData.isEditable}
                      />
                      <RadioButton
                        label="Barge Inspection Form(s) Complete: "
                        name="bargeInspFormsComp"
                        onChange={(event) =>
                          send('SYNC_FORM_DATA', {
                            field: event.target.name,
                            value: event.target.value,
                          })
                        }
                        value={state.context.formData.bargeInspFormsComp}
                        required={!state.context.formData.lightBoatFleet}
                        readOnly={!state.context.formData.isEditable}
                      />
                      <InputField
                        type="number"
                        label="# of Loads: "
                        name="numOfLoads"
                        onChange={(event) =>
                          send('SYNC_FORM_DATA', {
                            field: event.target.name,
                            value: event.target.value,
                          })
                        }
                        value={state.context.formData.numOfLoads}
                        required={!state.context.formData.lightBoatFleet}
                        highlight
                        readOnly={!state.context.formData.isEditable}
                        highlightOnClick
                      />
                      <InputField
                        type="number"
                        label="# of Empties: "
                        name="numOfEmpties"
                        onChange={(event) =>
                          send('SYNC_FORM_DATA', {
                            field: event.target.name,
                            value: event.target.value,
                          })
                        }
                        value={state.context.formData.numOfEmpties}
                        required={!state.context.formData.lightBoatFleet}
                        highlight
                        readOnly={!state.context.formData.isEditable}
                        highlightOnClick
                      />
                      <RadioButton
                        label="Calculated horsepower to tow ratio: "
                        name="calcHPToTowRatio"
                        onChange={(event) =>
                          send('SYNC_FORM_DATA', {
                            field: event.target.name,
                            value: event.target.value,
                          })
                        }
                        value={state.context.formData.calcHPToTowRatio}
                        required={!state.context.formData.lightBoatFleet}
                        readOnly={!state.context.formData.isEditable}
                      />
                    </>
                  )}
                  <RadioButton
                    label="Bridge and Lock dimensions verified: "
                    name="bridgeLockDimVerified"
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    value={state.context.formData.bridgeLockDimVerified}
                    required
                    readOnly={!state.context.formData.isEditable}
                  />
                  <RadioButton
                    label="Routes active in Rose Point (except in fleet): "
                    name="routesActiveRPExeptInFleet"
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    value={state.context.formData.routesActiveRPExeptInFleet}
                    required
                    readOnly={!state.context.formData.isEditable}
                  />
                  <RadioButton
                    label="AIS information current and up to date: "
                    name="currentUpdatedAISInfo"
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    value={state.context.formData.currentUpdatedAISInfo}
                    required
                    readOnly={!state.context.formData.isEditable}
                  />
                </div>
                <div className={classes.VPForm_section}>
                  <WWCTable
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    value={state.context.formData}
                    readOnly={!state.context.formData.isEditable}
                  />
                  <TextArea
                    label="Master's Notes"
                    name="mastersNotes"
                    rows={3}
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    value={state.context.formData.mastersNotes}
                    readOnly={!state.context.formData.isEditable}
                  />
                </div>
                <div className={classes.VPForm_section}>
                  <VTSTable
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    values={state.context.formData.vts}
                    readOnly={!state.context.formData.isEditable}
                  />
                </div>
                <div className={classes.VPForm_section}>
                  <InputField
                    type="text"
                    label="Filled out by: "
                    name="filledOutBy"
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    value={state.context.formData.filledOutBy}
                    required
                    readOnly={!state.context.formData.isEditable}
                  />
                </div>
              </div>
            )}
            {(state.context.showAllPages || state.context.page === 2) && (
              <div className={classes.VPForm_page_2} id="page_2">
                <h1 className={classes.VPForm_page_header}>
                  (Page 2 of 3) Filled Out For Vessels Transiting Outside Fleet
                </h1>
                <div className={classes.VPForm_section}>
                  <TextArea
                    label="Routes: "
                    name="routes"
                    rows={5}
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    value={state.context.formData.routes}
                    required
                    readOnly={!state.context.formData.isEditable}
                  />
                  <TextArea
                    label="Navigational Hazards: "
                    name="navigationHazards"
                    rows={5}
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    value={state.context.formData.navigationHazards}
                    readOnly={!state.context.formData.isEditable}
                  />
                </div>
                <div className={classes.VPForm_section}>
                  <OversizeTowPermitNum
                    value={state.context.formData.oversizeTowPermitNum || {}}
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    readOnly={!state.context.formData.isEditable}
                  />
                </div>
                <div className={classes.VPForm_section}>
                  <PreDepartureChecksComplete
                    value={
                      state.context.formData.preDepartureChecksComplete || {}
                    }
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    readOnly={!state.context.formData.isEditable}
                  />
                  <InputField
                    type="number"
                    label="Navigation Risk Assessment Score for Voyage: "
                    name="navRiskAssessmentScore"
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    value={state.context.formData.navRiskAssessmentScore}
                    readOnly={!state.context.formData.isEditable}
                  />
                </div>
                <div className={classes.VPForm_section}>
                  <VoyageCompletion
                    value={state.context.formData.voyageCompletion || {}}
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    readOnly={!state.context.formData.isEditable}
                  />
                </div>
              </div>
            )}
            {(state.context.showAllPages || state.context.page === 3) && (
              <div className={classes.VPForm_page_3} id="page_3">
                <h1 className={classes.VPForm_page_header}>
                  (Page 3 of 3) Filled Out By Vessels Towing Equipment At Or
                  Above Wheelhouse
                </h1>
                <div className={classes.VPForm_section}>
                  <ol className={classes.VPForm_clearance_instructions}>
                    <li>Item for Transit</li>
                    <li>Bridge Name Intended for Transit</li>
                    <li>Channel Span Vertical Clearance</li>
                    <li>
                      Current River Stage, using appropriate gauge for area
                    </li>
                    <li>C minus D, vertical clearance at river stage</li>
                    <li>Air Draft of Item (Cargo / Equipment / Crane)</li>
                    <li>E minus F, vertical clearance</li>
                  </ol>
                  <table className={classes.VPForm_clearance_cheatsheet}>
                    <thead>
                      <tr>
                        <th colSpan="3">LMR Bridge Channel Span Clearance</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Bridge</th>
                        <th>Center Channel Span Clearance</th>
                        <th>Minus River Stage @</th>
                      </tr>
                      <tr>
                        <td>Sunshine</td>
                        <td>170'</td>
                        <td>Donaldson</td>
                      </tr>
                      <tr>
                        <td>Gramery</td>
                        <td>164'</td>
                        <td>Reserve</td>
                      </tr>
                      <tr>
                        <td>Luling</td>
                        <td>158'</td>
                        <td>Reserve</td>
                      </tr>
                      <tr>
                        <td>Huey P. Long</td>
                        <td>153'</td>
                        <td>Carrollton</td>
                      </tr>
                      <tr>
                        <td>Crescent City Connection</td>
                        <td>170'</td>
                        <td>Carrollton</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className={classes.VPForm_section}>
                  <ClearanceTable
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    values={state.context.formData.towingAtOrAboveWheelhouse}
                    readOnly={!state.context.formData.isEditable}
                  />
                </div>
                <div className={classes.VPForm_section}>
                  <LockTable
                    onChange={(event) =>
                      send('SYNC_FORM_DATA', {
                        field: event.target.name,
                        value: event.target.value,
                      })
                    }
                    values={state.context.formData.lockClearance}
                    readOnly={!state.context.formData.isEditable}
                  />
                </div>
              </div>
            )}
            {state.context.page === 4 && (
              <div className={classes.VPForm_page_4} id="risk_assessments">
                {state.context.riskAssessmentCards}
              </div>
            )}
            <div
              className={classes.VPForm_risk_assessments}
              style={{ display: 'none' }}
            >
              {Object.keys(state.context.formData.riskAssessments).map(
                (key) => {
                  return (
                    <RiskAssessment
                      name={key}
                      key={key}
                      values={state.context.formData.riskAssessments}
                      onChange={() => true}
                      readOnly
                    />
                  );
                }
              )}
            </div>
          </div>
        </form>
      </>
    )
  );
};

export default VPForm;
