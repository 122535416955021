import classes from './LockTable.module.css';

const LockTable = ({ values = {}, readOnly = false, onChange }) => {
  const rows = Object.keys(values).length;

  const update = (event) => {
    const [key, field, subField = ''] = event.target.name.split('_');
    const input = event.target.value;
    subField === ''
      ? (values[key][field] = input)
      : (values[key][field][subField] = input);
    onChange({ target: { name: 'lockClearance', value: values } });
  };
  const addRowHandler = () => {
    values[`lockClearance${rows}`] = {
      dimensions: { length: 0, width: 0 },
      name: '',
    };
    onChange({ target: { name: 'lockClearance', value: values } });
  };
  const deleteRowHandler = () => {
    delete values[`lockClearance${rows - 1}`];
    onChange({ target: { name: 'lockClearance', value: values } });
  };
  const highlight = (event) => {
    if (document.getElementsByName(event.target.name).length > 0)
      document.getElementsByName(event.target.name)[0].select();
  };

  return (
    <>
      <h2>Lock(s) Clearance</h2>
      <table className={classes.LockTable}>
        <tbody>
          <tr>
            <th>Name</th>
            <th>Dimensions</th>
          </tr>
          {Object.keys(values).map((key) => (
            <tr key={key}>
              <td>
                <input
                  type="text"
                  name={`${key}_name`}
                  onChange={update}
                  value={values[key]['name']}
                  readOnly={readOnly}
                />
              </td>
              <td className={classes.LockTable_dimensions}>
                <div className={classes.LockTable_dimension}>
                  <label htmlFor="length">Length: </label>
                  <input
                    type="number"
                    id="length"
                    name={`${key}_dimensions_length`}
                    onChange={update}
                    onClick={highlight}
                    value={values[key].dimensions.length}
                    readOnly={readOnly}
                  />
                </div>
                <div className={classes.LockTable_dimension}>
                  <label htmlFor="width">Width: </label>
                  <input
                    type="number"
                    id="width"
                    name={`${key}_dimensions_width`}
                    onChange={update}
                    onClick={highlight}
                    value={values[key].dimensions.width}
                    readOnly={readOnly}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {!readOnly && (
        <div className={classes.rowButtons}>
          <button
            className={classes.rowButton}
            type="button"
            onClick={addRowHandler}
          >
            Add Row
          </button>
          {rows > 0 && (
            <button
              className={classes.rowButton}
              type="button"
              onClick={deleteRowHandler}
            >
              Delete Row
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default LockTable;
