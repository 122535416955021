import classes from './DurationPicker.module.css';

const DurationPicker = ({
  name,
  label,
  value = 0,
  required = false,
  readOnly = false,
  onChange,
}) => {
  const dayValue = Math.floor(value / 24);
  const hourValue = value % 24;

  const onUpdate = (event) => {
    let hours = 0;
    if (event.target.name.split('-')[1] === 'days')
      hours = Number(event.target.value) * 24 + hourValue;
    else if (event.target.name.split('-')[1] === 'hours')
      hours = dayValue * 24 + Number(event.target.value);
    if (hours < 0) hours = 0;

    onChange({
      target: { name: event.target.name.split('-')[0], value: hours },
    });
  };

  return (
    <div className={classes.duration}>
      <label className={classes.duration_label}>{label}</label>
      <div className={classes.duration_fields}>
        <div className={classes.duration_field}>
          <label htmlFor="days">Days: </label>
          <input
            type="number"
            id="days"
            name={`${name}-days`}
            onChange={onUpdate}
            value={dayValue}
            min="0"
            readOnly={readOnly}
            required={required}
          />
        </div>
        <div className={classes.duration_field}>
          <label htmlFor="hours">Hours: </label>
          <input
            type="number"
            id="hours"
            name={`${name}-hours`}
            onChange={onUpdate}
            value={hourValue}
            min="-1"
            readOnly={readOnly}
            required={required}
          />
        </div>
      </div>
    </div>
  );
};

export default DurationPicker;
